(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("leaflet"));
	else if(typeof define === 'function' && define.amd)
		define(["leaflet"], factory);
	else if(typeof exports === 'object')
		exports["L"] = factory(require("leaflet"));
	else
		root["L"] = root["L"] || {}, root["L"]["FreieTonne"] = factory(root["L"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__453__) {
return 