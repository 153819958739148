(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("leaflet"), require("leaflet-geometryutil"));
	else if(typeof define === 'function' && define.amd)
		define(["leaflet", "leaflet-geometryutil"], factory);
	else if(typeof exports === 'object')
		exports["L"] = factory(require("leaflet"), require("leaflet-geometryutil"));
	else
		root["L"] = root["L"] || {}, root["L"]["DraggableLines"] = factory(root["L"], root["L"]["GeometryUtil"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__453__, __WEBPACK_EXTERNAL_MODULE__7__) {
return 